<template>
  <div class="hidden md:flex print:hidden items-center justify-between h-16 px-10 bg-moduleTopbar">
    <div class="flex items-center gap-4 text-2xl font-medium">
      <slot />
      <button v-if="showModuleInfoButton" @click="toggleModuleInfo(currentRoute.name)">
        <svg viewBox="0 -960 960 960" class="h-6 text-brand/40 hover:text-brand">
          <path fill="currentColor" d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/>
        </svg>
      </button>
    </div>
    <!-- <NuxtLink v-if="!accountPage" to='/account' class="flex items-center gap-3">
      <div class="flex items-center justify-center h-10 w-10 overflow-hidden bg-white rounded-full">
        <img v-if="user?.picture_url" :src="user.picture_url" class="h-full w-full object-cover" />
        <svg v-else viewBox="0 -960 960 960" class="h-6">
          <path fill="#B8B5C4" d="M400-400q-50 0-85-35t-35-85q0-22 7-41.5t21-35.5q-4-10-6-21t-2-22q0-38 20.5-67.5T374-751q20-23 47-36t59-13q32 0 59 13t47 36q33 14 53.5 43.5T660-640q0 11-2 22t-6 21q14 16 21 35.5t7 41.5q0 50-35 85t-85 35H400ZM160-80v-112q0-34 17.5-62.5T224-298q62-31 126-46.5T480-360q66 0 130 15.5T736-298q29 15 46.5 43.5T800-192v112H160Z"/>
        </svg>
      </div>
      <div class="text-sm leading-6 tracking-[0.12px] font-semibold">
        {{ user.name }}
      </div>
    </NuxtLink> -->
  </div>
</template>

<script setup>
const { data } = useAuth()
const user = computed(() => data.value?.user)
const { currentRoute } = useRouter()
const accountPage = computed(() => currentRoute.value.name === 'account')
const { toggleModuleInfo } = useDialog()
const { showModuleInfo: showModuleInfoConfig } = useRuntimeConfig().public
const showModuleInfoButton = computed(() => showModuleInfoConfig.includes(currentRoute.value.name))
</script>
